import React from "react";
import Health from "../Health/Health";
import Stats from "../Stats/Stats";

export default function Plane({ settings, editPlane, idx, removePlane }) {
  const { ready } = settings;
  // const [planeStats, setPlaneStats] = useState(stats);
  // const [ready, setReady] = useState(false);
  // const [colour, setColour] = useState("#ffffff");
  // const [textColour, setTexColour] = useState("#000000");

  function handleStat(e, mod, type) {
    editPlane({ ...settings, [type]: settings[type] + mod }, settings.id);
  }

  function makeReady() {
    editPlane({ ...settings, ready: true }, settings.id);
  }

  function pickTextColorBasedOnBgColorAdvanced(bgColor, lightColor, darkColor) {
    const color = bgColor.charAt(0) === "#" ? bgColor.substring(1, 7) : bgColor;
    const r = parseInt(color.substring(0, 2), 16); // hexToR
    const g = parseInt(color.substring(2, 4), 16); // hexToG
    const b = parseInt(color.substring(4, 6), 16); // hexToB
    const uicolors = [r / 255, g / 255, b / 255];
    const c = uicolors.map((col) => {
      if (col <= 0.03928) {
        return col / 12.92;
      }
      return Math.pow((col + 0.055) / 1.055, 2.4);
    });
    const L = 0.2126 * c[0] + 0.7152 * c[1] + 0.0722 * c[2];
    return L > 0.179 ? darkColor : lightColor;
  }

  function handleChangeColour(e) {
    const bgColor = e.target.value;
    const color = pickTextColorBasedOnBgColorAdvanced(e.target.value, "#FFFFFF", "#000000");
    editPlane({ ...settings, color, bgColor }, settings.id);
  }

  return (
    <div className="plane" style={{ backgroundColor: settings.bgColor, color: settings.color }} key={settings.id}>
      <span onClick={(e) => removePlane(e, settings.id)} className="plane__delete">
        remove
      </span>
      {!ready && (
        <div className="colour">
          <span className="colour__title">Set Plane Colour:</span>
          <input
            type="color"
            onChange={handleChangeColour}
            value={settings.bgColor}
            className="colour__bg"
            style={{ border: `4px solid ${settings.color}` }}
          />
        </div>
      )}
      <Stats
        planeStats={{
          health: settings.health,
          defense: settings.defense,
          attack: settings.attack,
          manoeuvrability: settings.manoeuvrability,
        }}
        handleStat={handleStat}
        ready={ready}
        textColour={settings.color}
      />
      {ready && <Health startingHealth={settings.health} />}
      {!ready && (
        <button className="ready-btn" onClick={() => makeReady(true)}>
          Ready
        </button>
      )}
    </div>
  );
}
