import { useState, useEffect } from "react";
import "./Tracker.scss";
import Plane from "./Plane/Plane";

const planeStarter = {
  id: "yomama",
  health: 0,
  defense: 0,
  attack: 0,
  manoeuvrability: 0,
  color: "#000000",
  bgColor: "#ffffff",
  ready: false,
};
function Tracker() {
  const [planes, setPlanes] = useState([]);

  useEffect(() => {
    if (localStorage.getItem("planes")) {
      setPlanes(JSON.parse(localStorage.getItem("planes")));
    }
  }, []);

  function makeid(length) {
    let result = "";
    const characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    const charactersLength = characters.length;
    let counter = 0;
    while (counter < length) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
      counter += 1;
    }
    return result;
  }

  function addPlane() {
    const newId = makeid(10);
    const newPlane = { ...planeStarter, id: newId };
    if (planes.length === 0) {
      newPlane.bgColor = "#16c2d9";
    } else if (planes.length === 1) {
      newPlane.bgColor = "#1cad43";
    } else if (planes.length === 2) {
      newPlane.bgColor = "#fffb4a";
    } else if (planes.length === 3) {
      newPlane.bgColor = "#f8903a";
    }
    setPlanes([...planes, newPlane]);
  }

  function editPlane(newPlane, id) {
    const tempPlanes = [...planes];

    tempPlanes.forEach((item, idx) => {
      if (item.id === id) {
        tempPlanes[idx] = newPlane;
      }
    });
    localStorage.setItem("planes", JSON.stringify(tempPlanes));
    setPlanes(tempPlanes);
  }

  function removePlane(e, id) {
    const tempPlanes = planes.filter(function (item) {
      return item.id !== id;
    });
    localStorage.setItem("planes", JSON.stringify(tempPlanes));
    setPlanes(tempPlanes);
  }

  return (
    <>
      <div className="content">
        <h1>Squadron Leader Plane Tracker</h1>
        <p>Use this tool to keep track of as many planes as you like.</p>
        <p>
          This is particularly useful if you have more plane tokens than Stat Boards, or if you don't have the space on the
          table for them at all.
        </p>
      </div>
      <div className="tracker">
        <button onClick={addPlane} className="add-btn">
          Add Plane
        </button>
        <div className="planes-wrap">
          {planes.map((p, idx) => (
            <Plane settings={p} idx={idx} removePlane={removePlane} editPlane={editPlane} />
          ))}
        </div>
      </div>
    </>
  );
}

export default Tracker;
