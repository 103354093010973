import { ReactComponent as Health } from "../assets/health.svg";
import { ReactComponent as Defense } from "../assets/defense.svg";
import { ReactComponent as Attack } from "../assets/attack.svg";
import { ReactComponent as Manoeuvrability } from "../assets/manoeuvrability.svg";

export default function Stats({ planeStats, handleStat, ready, textColour }) {
  return (
    <div className="stats">
      {Object.keys(planeStats).map((stat, idx) => {
        return (
          <div className="stats__stat" key={idx}>
            {!ready && (
              <span className="stats__stat-increase" onClick={(e) => handleStat(e, 1, stat)}>
                +
              </span>
            )}
            {stat === "health" && <Health fill={textColour} className="stats__stat-icon" />}
            {stat === "defense" && <Defense fill={textColour} className="stats__stat-icon" />}
            {stat === "attack" && <Attack fill={textColour} className="stats__stat-icon" />}
            {stat === "manoeuvrability" && <Manoeuvrability fill={textColour} className="stats__stat-icon" />}
            <p className="stats__stat-value">{planeStats[stat]}</p>

            {!ready && (
              <span className="stats__stat-decrease" onClick={(e) => handleStat(e, -1, stat)}>
                -
              </span>
            )}
          </div>
        );
      })}
    </div>
  );
}
